import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import yaml from 'js-yaml';
import {
  RecordTypeContent,
  ReferenceDataContent,
  TermsOfUseContent,
  TranslationContent
} from '@core/models';
import { Observable, map } from 'rxjs';

export enum Library {
  DefaultRecordType = 'common/default-record-type.yaml',
  DefaultRecordTypeTranslation = 'common/default-record-type-en-gb-translation.yaml',
  DefaultDashboard = 'common/default-dashboard.yaml',
  DefaultDashboardTranslation = 'common/default-dashboard-en-gb-translation.yaml',
  GlobalTranslation = 'common/global-en-gb-translation.yaml',
  LocationRecordType = 'common/location-type.yaml',
  LocationTranslation = 'common/location-en-gb-translation.yaml',
  PersonRecordType = 'common/person-type.yaml',
  PersonTranslation = 'common/person-en-gb-translation.yaml',
  RecordTypeTranslation = 'common/record-type-en-gb-translation.yaml',
  StarterRecordRecordType = 'starter/record-type.yaml',
  StarterRecordTranslation = 'starter/record-en-gb-translation.yaml',
  StarterReferenceData = 'starter/reference-data.yaml',
  StarterReferenceDataTranslation = 'starter/reference-en-gb-translation.yaml',
  StarterHomePageDocument = 'starter/home-page-document.yaml',
  StarterHomePageTranslation = 'starter/home-page-en-gb-translation.yaml',
  DashboardContentSchema = 'schemas/dashboard-content-schema.yaml',
  DocumentSchema = 'schemas/document-schema.yaml',
  RecordContentSchema = 'schemas/record-content-schema.yaml',
  RecordTypeContentSchema = 'schemas/record-type-content-schema.yaml',
  ReferenceDataContentSchema = 'schemas/reference-data-content-schema.yaml',
  TranslationContentSchema = 'schemas/translation-content-schema.yaml',
  CmpOrganisationRecordType = 'cmp/organisation-type.yaml',
  CmpOrganisationTranslation = 'cmp/organisation-en-gb-translation.yaml',
  CmpPlatformMemberRecordType = 'cmp/platform-member-type.yaml',
  CmpPlatformMemberTranslation = 'cmp/platform-member-en-gb-translation.yaml',
  CmpPolicyRecordType = 'cmp/policy-type.yaml',
  CmpPolicyTranslation = 'cmp/policy-en-gb-translation.yaml',
  CmpWorkspaceRecordType = 'cmp/workspace-type.yaml',
  CmpWorkspaceTranslation = 'cmp/workspace-en-gb-translation.yaml',
  CmpReferenceData = 'cmp/reference-data-document.yaml',
  CmpReferenceDataTranslation = 'cmp/reference-data-en-gb-translation.yaml',
  CmpHomePageDocument = 'cmp/home-page-document.yaml',
  CmpHomePageTranslation = 'cmp/home-page-en-gb-translation.yaml',
  CmpWorkspaceDataDocument = 'cmp/workspace-data-document.yaml',
  StarterWorkspaceDataDocument = 'starter/workspace-data-document.yaml',
  UserDataDocument = 'common/user-data-document.yaml',
  TermsOfUseContentSchema = 'schemas/terms-of-use-content-schema.yaml',
  DefaultTermsOfUseDocument = 'common/default-terms-of-use-document.yaml',

  SmartAuditV2AuditRecordType = 'smart-audit-v2/audit-type.yaml',
  SmartAuditV2AuditTranslation = 'smart-audit-v2/audit-en-gb-translation.yaml',
  SmartAuditV2ChecklistRecordType = 'smart-audit-v2/checklist-type.yaml',
  SmartAuditV2ChecklistTranslation = 'smart-audit-v2/checklist-en-gb-translation.yaml',
  SmartAuditV2FindingRecordType = 'smart-audit-v2/finding-type.yaml',
  SmartAuditV2FindingTranslation = 'smart-audit-v2/finding-en-gb-translation.yaml',
  SmartAuditV2GlobalTranslation = 'smart-audit-v2/global-en-gb-translation.yaml',
  SmartAuditV2HomePageDocument = 'smart-audit-v2/home-page-document.yaml',
  SmartAuditV2HomePageTranslation = 'smart-audit-v2/home-page-en-gb-translation.yaml',
  SmartAuditV2LocationRecordType = 'smart-audit-v2/location-type.yaml',
  SmartAuditV2LocationTranslation = 'smart-audit-v2/location-en-gb-translation.yaml',
  SmartAuditV2PersonRecordType = 'smart-audit-v2/person-type.yaml',
  SmartAuditV2PersonTranslation = 'smart-audit-v2/person-en-gb-translation.yaml',
  SmartAuditV2RecordTypeTranslation = 'smart-audit-v2/record-type-en-gb-translation.yaml',
  SmartAuditV2ReferenceData = 'smart-audit-v2/reference-data-document.yaml',
  SmartAuditV2ReferenceDataTranslation = 'smart-audit-v2/reference-data-en-gb-translation.yaml',
  SmartAuditV2WorkspaceDataDocument = 'smart-audit-v2/workspace-data-document.yaml',
  AutomationActionRecordType = 'automation/action-type.yaml',
  AutomationActionTranslation = 'automation/action-en-gb-translation.yaml',
  AutomationAuditRecordType = 'automation/audit-type.yaml',
  AutomationAuditTranslation = 'automation/audit-en-gb-translation.yaml',
  AutomationChecklistRecordType = 'automation/checklist-type.yaml',
  AutomationChecklistTranslation = 'automation/checklist-en-gb-translation.yaml',
  AutomationFindingRecordType = 'automation/finding-type.yaml',
  AutomationFindingTranslation = 'automation/finding-en-gb-translation.yaml',
  AutomationGlobalTranslation = 'automation/global-en-gb-translation.yaml',
  AutomationHomePageDocument = 'automation/home-page-document.yaml',
  AutomationHomePageTranslation = 'automation/home-page-en-gb-translation.yaml',
  AutomationLocationRecordType = 'automation/location-type.yaml',
  AutomationLocationTranslation = 'automation/location-en-gb-translation.yaml',
  AutomationPersonRecordType = 'automation/person-type.yaml',
  AutomationPersonTranslation = 'automation/person-en-gb-translation.yaml',
  AutomationRecordTypeTranslation = 'automation/record-type-en-gb-translation.yaml',
  AutomationReferenceData = 'automation/reference-data-document.yaml',
  AutomationReferenceDataTranslation = 'automation/reference-data-en-gb-translation.yaml',
  AutomationWorkspaceDataDocument = 'automation/workspace-data-document.yaml',

  TakeawayActionRecordType = 'takeaway/action-type.yaml',
  TakeawayActionTranslation = 'takeaway/action-en-gb-translation.yaml',
  TakeawayAuditRecordType = 'takeaway/audit-type.yaml',
  TakeawayAuditTranslation = 'takeaway/audit-en-gb-translation.yaml',
  TakeawayChecklistRecordType = 'takeaway/checklist-type.yaml',
  TakeawayChecklistTranslation = 'takeaway/checklist-en-gb-translation.yaml',
  TakeawayFindingRecordType = 'takeaway/finding-type.yaml',
  TakeawayFindingTranslation = 'takeaway/finding-en-gb-translation.yaml',
  TakeawayGlobalTranslation = 'takeaway/global-en-gb-translation.yaml',
  TakeawayHomePageDocument = 'takeaway/home-page-document.yaml',
  TakeawayHomePageTranslation = 'takeaway/home-page-en-gb-translation.yaml',
  TakeawayLocationRecordType = 'takeaway/location-type.yaml',
  TakeawayLocationTranslation = 'takeaway/location-en-gb-translation.yaml',
  TakeawayPersonRecordType = 'takeaway/person-type.yaml',
  TakeawayPersonTranslation = 'takeaway/person-en-gb-translation.yaml',
  TakeawayRecordTypeTranslation = 'takeaway/record-type-en-gb-translation.yaml',
  TakeawayReferenceData = 'takeaway/reference-data-document.yaml',
  TakeawayReferenceDataTranslation = 'takeaway/reference-data-en-gb-translation.yaml',
  TakeawayWorkspaceDataDocument = 'takeaway/workspace-data-document.yaml',
  TakeawayTermsOfUseDocument = 'takeaway/terms-of-use-document.yaml',

  PlusPremiumActionRecordType = 'plus-premium/action-type.yaml',
  PlusPremiumActionTranslation = 'plus-premium/action-en-gb-translation.yaml',
  PlusPremiumAuditRecordType = 'plus-premium/audit-type.yaml',
  PlusPremiumAuditTranslation = 'plus-premium/audit-en-gb-translation.yaml',
  PlusPremiumChecklistRecordType = 'plus-premium/checklist-type.yaml',
  PlusPremiumChecklistTranslation = 'plus-premium/checklist-en-gb-translation.yaml',
  PlusPremiumFindingRecordType = 'plus-premium/finding-type.yaml',
  PlusPremiumFindingTranslation = 'plus-premium/finding-en-gb-translation.yaml',
  PlusPremiumGlobalTranslation = 'plus-premium/global-en-gb-translation.yaml',
  PlusPremiumHomePageDocument = 'plus-premium/home-page-document.yaml',
  PlusPremiumHomePageTranslation = 'plus-premium/home-page-en-gb-translation.yaml',
  PlusPremiumLocationRecordType = 'plus-premium/location-type.yaml',
  PlusPremiumLocationTranslation = 'plus-premium/location-en-gb-translation.yaml',
  PlusPremiumPersonRecordType = 'plus-premium/person-type.yaml',
  PlusPremiumPersonTranslation = 'plus-premium/person-en-gb-translation.yaml',
  PlusPremiumRecordTypeTranslation = 'plus-premium/record-type-en-gb-translation.yaml',
  PlusPremiumReferenceData = 'plus-premium/reference-data-document.yaml',
  PlusPremiumReferenceDataTranslation = 'plus-premium/reference-data-en-gb-translation.yaml',
  PlusPremiumWorkspaceDataDocument = 'plus-premium/workspace-data-document.yaml',
  PlusPremiumTermsOfUseDocument = 'plus-premium/terms-of-use-document.yaml',

  IntegrationFindingRecordType = 'integration/finding-type.yaml',
  IntegrationFindingTranslation = 'integration/finding-en-gb-translation.yaml',
  IntegrationReferenceDataDocument = 'integration/reference-data-document.yaml',
  IntegrationReferenceDataTranslation = 'integration/reference-data-en-gb-translation.yaml',
  IntegrationRecordType = 'integration/integration-type.yaml',
  IntegrationTranslation = 'integration/integration-en-gb-translation.yaml',
  IntegrationWorkspaceRecordType = 'integration/workspace-type.yaml',
  IntegrationWorkspaceDataDocument = 'integration/workspace-data-document.yaml',
  IntegrationWorkspaceTranslation = 'integration/workspace-en-gb-translation.yaml',
  ServiceAccountRecordType = 'common/serviceaccount-type.yaml',
  ServiceAccountTranslation = 'common/serviceaccount-en-gb-translation.yaml',
}

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  private baseUri = '/assets/library/';
  private options = { responseType: 'text' as 'json' };

  constructor(private http: HttpClient) { }

  getItem(item: Library): Observable<any> {
    return this.http.get(this.baseUri + item, this.options)
      .pipe(
        map((response: any) => yaml.load(response)),
      );
  }

  getDocument(item: Library): Observable<any> {
    return this.getItem(item);
  }

  getRecordType(item: Library): Observable<RecordTypeContent> {
    return this.getItem(item) as Observable<RecordTypeContent>;
  }

  getReferenceData(item: Library): Observable<ReferenceDataContent> {
    return this.getItem(item) as Observable<ReferenceDataContent>;
  }

  getTranslation(item: Library): Observable<TranslationContent> {
    return this.getItem(item) as Observable<TranslationContent>;
  }

  getTermsOfUse(item: Library): Observable<TermsOfUseContent> {
    return this.getItem(item) as Observable<TermsOfUseContent>;
  }
}
